<template>
  <div class="mt-6">
    <div v-for="(item, index) in items" :key="`pte-practice-${index}`">
      <div
        class="p-6 rounded flex justify-between pointer align-center mb-3"
        :style="{ backgroundColor: item.bgColor }"
        @click="toggleAccordion(item.slug)"
      >
        <p class="text-white text-uppercase text-2xl font-semibold">
          <span
            v-if="item.pill"
            class="text-sm bg-white p-1 rounded mr-3"
            :style="{ color: item.bgColor }"
            >{{ item.pill }}</span
          >
          {{ item.name }}
        </p>
        <div>
          <span class="text-base text-white opacity-75 mr-3"
            >{{ item.test }} Test</span
          >
          <md-icon class="outline-gray-400 text-white p-4 rounded"
            >arrow_drop_down</md-icon
          >
        </div>
      </div>
      <div v-if="item.slug == activeItem" class="p-4 mb-4" style="max-height: 350px; overflow-y: auto">
        <template v-if="childItems.length > 0">
          <div
            class="p-4 my-2 text-gray-900 rounded bg-link-water flex justify-between pointer align-center"
            v-for="child in childItems"
            :key="child.id"
            @click="$emit('on-navigate', {item, child})"
          >
            <p class="text-uppercase text-xl font-semibold">
              {{ child.name }}
            </p>
            <div>
              <md-icon class="bg-periwinkle-gray text-white p-4 rounded-full"
                >chevron_right</md-icon
              >
            </div>
          </div>
         </template>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      active: 0,
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    activeItem: {
      type: String,
      required: true
    },
    childItems: {
      type: Array
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    }
  },
  methods: {
    ...mapActions({
      actSectionWiseTests: "testCreation/actSectionWiseTests"
    }),
    toggleAccordion(type) {
      if(this.type == type) return;
      // this.$router.push({
      //   name: 'student.ptePractice.collapse',
      //   query: {
      //     type: type
      //   }
      // });
      this.actSectionWiseTests(`?section=${this.type}`);
    },
  },
};
</script>

<style></style>
