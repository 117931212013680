<template>
  <div>
    <div
      class="rounded align-center p-3 flex justify-between bg-link-water"
    >
      <h2 class="text-lg font-bold text-primary text-uppercase">
        PTE A
      </h2>
      <div class="flex align-center">
        <div class="bg-periwinkle-gray rounded p-2 ml-2 pointer">
          <md-icon class="m-0">close</md-icon>
        </div>
      </div>
    </div>
    <div class="flex justify-between align-center mt-6">
      <div class="bg-victoria text-white inline-flex p-2 rounded align-center">
        <h2>PTD</h2>
        <div class="border-l border-solid border-gray-200 pl-3 ml-3">
          <p class="text-uppercase text-base line-height-27">person test <br> of english academic</p>
        </div>
      </div>
      <div>
        <img src="/icon.png" alt="">
      </div>
    </div>
    <div class="w-75p">
      <h2 class="text-uppercase mb-2 mt-4">Headset check</h2>
      <p class="text-base line-height-27">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus, architecto nemo delectus, exercitationem aut ratione animi repellendus cupiditate mollitia rem, hic doloremque eligendi sapiente nihil quod tempora id dignissimos soluta ducimus? Ratione consectetur eaque, minus nam laboriosam voluptatibus dicta quos eveniet a possimus obcaecati officiis. Commodi illo quas expedita perspiciatis, at dolorum reprehenderit pariatur, atque, facilis illum officia. Voluptates dolorum pariatur repellat odit quaerat? Fuga ipsa sequi debitis dolorem vitae ipsam totam beatae soluta officia fugit quas possimus ipsum laboriosam provident voluptatem maiores unde eveniet, nisi officiis! Nam, repellat quibusdam? A optio sunt odio corrupti cupiditate numquam dolores accusantium laborum? Nostrum sed ratione labore mollitia hic eaque blanditiis autem cum corporis eius quos aperiam modi quam ipsa eum, magnam ullam iure dolore voluptatum quod? Sapiente magnam amet dolorem ex quos ad ipsam aliquam reprehenderit. Nisi iste necessitatibus nulla illo, autem odit porro nostrum ea eaque earum cumque, placeat nemo eveniet.</p>
      <audio-player src="/1.mp3" class="my-6"/>
      <p class="text-base line-height-27">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus, architecto nemo delectus, exercitationem aut ratione animi repellendus cupiditate mollitia rem, hic doloremque eligendi sapiente nihil quod tempora id dignissimos soluta ducimus? Ratione consectetur eaque, minus nam laboriosam voluptatibus dicta quos eveniet a possimus obcaecati officiis. Commodi illo quas expedita perspiciatis, at dolorum reprehenderit pariatur, atque, facilis illum officia. Voluptates dolorum pariatur repellat odit quaerat? Fuga ipsa sequi debitis dolorem vitae ipsam totam beatae soluta officia fugit quas possimus ipsum laboriosam provident voluptatem maiores unde eveniet, nisi officiis! Nam, repellat quibusdam? A optio sunt odio corrupti cupiditate numquam dolores accusantium laborum? Nostrum sed ratione labore mollitia hic eaque blanditiis autem cum corporis eius quos aperiam modi quam ipsa eum, magnam ullam iure dolore voluptatum quod? Sapiente magnam amet dolorem ex quos ad ipsam aliquam reprehenderit. Nisi iste necessitatibus nulla illo, autem odit porro nostrum ea eaque earum cumque, placeat nemo eveniet.</p>
    </div>
    <div class="px-2 py-1 bg-link-water rounded font-semibold mt-16">
      Click Next Button Below to Continue
    </div>
    <div class="flex justify-end mt-3">
      <md-button class="bg-victoria text-white m-0 rounded">Next</md-button>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>