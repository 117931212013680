<template>
  <div>
    <div class="flex bg-link-water p-6 rounded">
      <md-icon class="m-0 text-victoria text-3xl">article</md-icon>
      <h3 class="text-uppercase text-victoria ml-3 text-2xl font-bold">MOCK Exam</h3>
    </div>
    <CollapseComponent :items="items" @on-navigate="onNavigate"/>
    <div class="mt-6">
      <md-button class="bg-victoria text-white rounded m-0">Back </md-button>
    </div>
  </div>
</template>

<script>
import CollapseComponent from "@/components/molecule/CollapseComponent";
import data from '@/data/mock-test/data'
export default {
  data() {
      return {
          items: data.mockTestNavigation
      }
  },
  components: {
    CollapseComponent,
  },
  mounted(){
   console.log("mock",this.items)
  },
  methods: {
    onNavigate({item, child}) {
      this.$router.push({
        name: 'student.mockExamPreview',
        params: {
          slug: item.slug,
          id: child.id
        }
      });
    }
  }
};
</script>

<style></style>
